import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import { addComplaintDetails, getComplaintType } from '../../../services/complaint.service';
import { showLoader } from '../../../services/loader.services';
import { getLicenseKey } from '../../../services/application.settings';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import Textarea from '../../bootstrap/forms/Textarea';
import AlertService from '../../../services/AlertService';
import { getProfileForStudent } from '../../../services/student.service';
import  Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddComplaint = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getComplaintList();
		getStudentProfile(userAccountId);
	}, []);

	const [complaintTypeId, setComplaintTypeId] = useState<any>('');
	const [complaintData, setComplaintData] = useState<any>([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [natureOfComplaint, setNatureOfComplaint] = useState<any>('');

	const addNatureOfComplaint = (content: any, delta: any, source: any, editor: any) => {
		setNatureOfComplaint(editor.getHTML());
	};

	const complaintTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			natureOfComplaint: '',
			complaintTypeId: '',
		},
		validate: (values) => {
			const errors: {
				natureOfComplaint?: string;
				complaintTypeId?: string;
			} = {};
			if (!natureOfComplaint) {
				errors.natureOfComplaint = 'Required';
			}
			if (!complaintTypeId) {
				errors.complaintTypeId = 'Required';
			}

			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addComplaintSubmit();
		},
	});

	const SelectComplaint = (e: any) => {
		let complaintTypeId = e;
		setComplaintTypeId(complaintTypeId);
	};

	function getComplaintList() {
		getComplaintType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintType;
					if (data != undefined) {
						setComplaintData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setComplaintData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile[0];
					if (data != undefined) {
						setStudentDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setComplaintDetails() {
		return {
			studentOrStaffDetailsId: userAccountId,
			standardDetailsId: userTypeId == 9 ? studentDetailsData?.standardDetailsId : 0,
			complaintTypeId: complaintTypeId?.value,
			natureOfComplaint: natureOfComplaint,
			userTypeId: userTypeId,
			licenseKey: getLicenseKey,
		};
	}

	function addComplaintSubmit() {
		showLoader(true);
		if (complaintTypeForm.isValid) {
			let complaintData = setComplaintDetails();
			addComplaintDetails(
				complaintData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (complaintTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function closeAndReset() {
		complaintTypeForm.resetForm();
		setComplaintTypeId('');
		setNatureOfComplaint('')
	}	

	return (
		<PageWrapper title=' Add Complaint'>
			<Page container='fluid'>
				<Card stretch>
					<CardHeader borderSize={1}>
						<CardLabel icon='Feedback' iconColor='info'>
							<CardTitle>Add Complaint</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row mt-3'>
							<div className='col d-flex align-items-center'>
								<div className=' col-2'>
									<strong> Complaint Type</strong>
								</div>
								<div className=''>
									<strong> : </strong>
								</div>
								<div
									className='col-4 ms-4 justify-content-center'
									style={{ width: '38%' }}>
									<FormGroup id='complaintTypeId' >
										<SearchableSelect
											ariaLabel='Complaint Type'
											placeholder='Complaint Type'
											onChange={SelectComplaint}
											value={complaintTypeId}
											list={complaintData.map((data: any) => ({
												value: data.complaintTypeId,
												label: data.complaintType,
											}))}
										/>
									</FormGroup>
								</div>
							</div>
						</div>

						<div className='row mt-4'>
							<div className='col d-flex'>
								<div className=' col-2'>
									<strong> Nature Of Complaint</strong>
								</div>
								<div className=''>
									<strong> : </strong>
								</div>
								<div className='col-9 ms-4'>
									<Quill
										style={{ height: '28vh' }}
										value={natureOfComplaint}
										onChange={addNatureOfComplaint}
										placeholder="Nature Of Complaint"
									/>
									{/* <FormGroup
										id='natureOfComplaint'
										label='Enter Complaint'
										isFloating>
										<Textarea
											rows={6}
											placeholder='Enter Complaint'
											onChange={complaintTypeForm.handleChange}
											value={complaintTypeForm.values.natureOfComplaint}
											style={{ height: '200px', width: '105%' }}
											onBlur={complaintTypeForm.handleBlur}
											isValid={complaintTypeForm.isValid}
											isTouched={complaintTypeForm.touched.natureOfComplaint}
											invalidFeedback={
												complaintTypeForm.errors.natureOfComplaint
											}
										/>
									</FormGroup> */}
								</div>
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<div className='col-12 mt-4 d-flex justify-content-end'>
							<Button
							className='mt-4'
								color='info'
								isLight
								isDisable={natureOfComplaint == '' ? true : false}
								onClick={complaintTypeForm.handleSubmit}
								icon='Save'>
								Submit
							</Button>
						</div>
					</CardFooter>
				</Card>
			</Page>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddComplaint;
