import React, { useContext, useEffect, useState } from 'react';
import Card, { CardHeader, CardLabel, CardTitle, CardSubTitle, CardBody, CardActions } from '../../bootstrap/Card';
import Checks from '../../bootstrap/forms/Checks';
import Progress from '../../bootstrap/Progress';
import Page from '../../../layout/Page/Page';
import { toasts } from '../../../services/toast.service';
import { getStudentHomeWorkList, updateStudentHomeWorkStatus } from '../../../services/class.service';
import classNames from 'classnames';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { showLoader } from '../../../services/loader.services';
import Icon from '../../icon/Icon';
import AlertService from '../../../services/AlertService';
import Button from '../../bootstrap/Button';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

const StudentHomeWork = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getStudentHomeWorkDetails(userAccountId);
	}, []);


	const [studentHomeWorkStatusId, setStudentHomeWorkStatusId] = useState<any>('');

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);

	const [list, setList] = useState<any>([]);
	const listLength =  list.filter((i: any) => i.homeWork != '').length;
	const completeTaskLength = list.filter((i: any) => i.homeWorkStatusId && i.homeWork != '').length;

	const [homeWorkDate, setHomeWorkDate] = useState<any>('');

	function getStudentHomeWorkDetails(studentDetailsId: any) {
		getStudentHomeWorkList(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentHomeWorkList[0].homeWorkDetails;
					if (data != undefined) {
						setHomeWorkDate(response.data.data.studentHomeWorkList[0].dateOfHomeWorkForView);
						setStudentHomeWorkStatusId(response.data.data.studentHomeWorkList[0].studentHomeWorkStatusId);
						setList(data);
						setDataSuccess(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setList([]);
					setDataSuccess(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}


	function updateHomeWorkSubmit(postData: any) {
		showLoader(true);
		let homeWorkPostData = postData;
		updateStudentHomeWorkStatus(
			homeWorkPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					getStudentHomeWorkDetails(userAccountId);
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	const handleChange = (_index: number, itemData: any) => {
		const newTodos = [...list];
		newTodos[_index].homeWorkStatusId = !newTodos[_index].homeWorkStatusId;
		setList(newTodos);

		let postData = {
			licenseKey: getLicenseKey,
			studentHomeWorkStatusId: studentHomeWorkStatusId,
			homeWork1Status: (_index == 0 && newTodos[0].homeWorkStatusId == true) || itemData[0].homeWorkStatusId == true ? 1 : 0,
			homeWork2Status: (_index == 1 && newTodos[1].homeWorkStatusId == true) || itemData[1].homeWorkStatusId == true ? 1 : 0,
			homeWork3Status: (_index == 2 && newTodos[2].homeWorkStatusId == true) || itemData[2].homeWorkStatusId == true ? 1 : 0,
			homeWork4Status: (_index == 3 && newTodos[3].homeWorkStatusId == true) || itemData[3].homeWorkStatusId == true ? 1 : 0,
			homeWork5Status: (_index == 4 && newTodos[4].homeWorkStatusId == true) || itemData[4].homeWorkStatusId == true ? 1 : 0,
			homeWork6Status: (_index == 5 && newTodos[5].homeWorkStatusId == true) || itemData[5].homeWorkStatusId == true ? 1 : 0,
			studentDetailsId: userAccountId,
		};

		updateHomeWorkSubmit(postData);
	};

	return (
		<PageWrapper title={`Home Work`}>
			<Page container='fluid'>
				<Card stretch className='border border-light' shadow={'md'}>
					<CardHeader borderSize={1}>
						<CardLabel icon='MenuBook' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								Home Work
							</CardTitle>
							<CardSubTitle tag='div'>
								<Progress
									height={8}
									isAnimated
									max={listLength}
									value={completeTaskLength}
									color={completeTaskLength === listLength ? 'success' : 'primary'}
								/>
							</CardSubTitle>
						</CardLabel>
						<CardActions><div className="mt-2 text-muted fs-5 fw-bold">{homeWorkDate}</div></CardActions>
					</CardHeader>

					<CardBody isScrollable>
						{dataSuccess ? (
							<>
								{list != '' ? (
									<>
										{list.map((itemData: any, index: any) => (
											<div className={classNames('todo-item')} key={index}>
												{itemData.homeWork != '' ? <>
													<div className='todo-bar'>
														<div className={classNames('h-100 w-100', 'rounded', { [`bg-primary`]: '' })} />
													</div>
													<div className='todo-check'>
														<Checks
															style={{ height: '17px', width: '17px' }}
															checked={itemData?.homeWorkStatusId == 1 ? true : false}
															onChange={() => handleChange(index, list)}
															ariaLabel={itemData.homeWork as string}
															className='border border-primary'
														/>
													</div>
													<div className='todo-content fs-5'>
														<div className={classNames('todo-title ms-3 mt-3', { 'text-decoration-line-through': itemData?.homeWorkStatusId == 1 ? true : false })}>
															{itemData.homeWorkDescription != 1 ? itemData.homeWorkDescription : 'Not Assign'}
														</div>
														{itemData.homeWork && (
															<div className='todo-subtitle small ms-3 text-primary'>
																{itemData.homeWork}
															</div>
														)}
													</div>
													<div className={classNames('todo-title mt-3', { 'text-success': itemData?.homeWorkStatusId == 1 ? true : false })}>
														{itemData.statusName == 'Assigned' ?
															<Button isLink color='info' icon='Circle' className='text-nowrap text-start'>
																{itemData.statusName}
															</Button> :

															<Button isLink color='success' icon='Circle' className='text-nowrap text-start'>
																{itemData.statusName}
															</Button>
														}
													</div> </> : null
												}
											</div>
										))}
									</>
								) : (
									<div className='row'>
										<div className='col-12' style={{ height: '15vh' }}></div>
										<div className='col-12 d-flex justify-content-center'>
											<Icon icon='DoNotDisturb' size={'10x'} />
										</div>
										<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
											Home Work NotAssigned
										</div>
									</div>
								)}
							</>
						) : null}
					</CardBody>
				</Card>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={false} />

			</Page>
		</PageWrapper>
	);
};

export default StudentHomeWork;
