import React, { useContext, useEffect, useState } from 'react';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { getAcademicPeriod, getBatchMasterDetails } from '../../../services/master.service';
import { getMedium, getStandardDetails, onlyAllowNumber } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import { useFormik } from 'formik';
import Icon from '../../icon/Icon';
import {
	addQuizQuestions,
	deleteQuizQuestions,
	getQuizDetails,
	getSubjectListByStandardDetailsId,
	updateQuizQuestions,
} from '../../../services/exam.service';
import DeleteComponents from '../../../common/components/DeleteComponents';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../../bootstrap/Modal';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Textarea from '../../bootstrap/forms/Textarea';
import Label from '../../bootstrap/forms/Label';
import { showLoader } from '../../../services/loader.services';
import useDarkMode from '../../../hooks/useDarkMode';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import AlertService from '../../../services/AlertService';

const QuizList = () => {
	useEffect(() => {
		getAcademicPeriodList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [batchMasterId, setBatchMasterId] = useState<any>('');
	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>('');
	const [subjectPaperDetailsId, setSubjectPaperDetailsId] = useState<any>('');
	const [correctAnswer, setCorrectAnswer] = useState<any>('');
	const [questionCount, setQuestionCount] = useState<any>('');

	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [mediumData, setMediumData] = useState<any>([]);
	const [batchMasterData, setBatchMasterData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [subjectData, setSubjectData] = useState<any>([]);
	const [quizData, setQuizData] = useState<any>([]);
	const [addQuestionData, setAddQuestionData] = useState<any>([]);
	const [quizTable, setQuizTable] = useState<any>([]);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [editQuestionModal, setEditQuestionModal] = useState(false);
	const [addQuestionModal, setAddQuestionModal] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const editQuestionTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			question: '',
			option1Value: '',
			option2Value: '',
			option3Value: '',
			option4Value: '',
			correctAnswer: '',
		},
		validate: (values) => {
			const errors: {
				question?: string;
				option1Value?: string;
				option2Value?: string;
				option3Value?: string;
				option4Value?: string;
			} = {};
			if (!values.question) {
				errors.question = '';
			}
			if (!values.option1Value) {
				errors.option1Value = '';
			}
			if (!values.option2Value) {
				errors.option2Value = '';
			}
			if (!values.option3Value) {
				errors.option3Value = '';
			}
			if (!values.option4Value) {
				errors.option4Value = '';
			}

			return errors;
		},
		//validateOnChange: false,
		onSubmit: (values) => { updateQuizQuestionSubmit() }
	});

	const addQuestionTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			question: '',
			option1Value: '',
			option2Value: '',
			option3Value: '',
			option4Value: '',
		},
		validate: (values) => {
			const errors: {
				question?: string;
				option1Value?: string;
				option2Value?: string;
				option3Value?: string;
				option4Value?: string;
			} = {};
			if (!values.question) {
				errors.question = '';
			}
			if (!values.option1Value) {
				errors.option1Value = '';
			}
			if (!values.option2Value) {
				errors.option2Value = '';
			}
			if (!values.option3Value) {
				errors.option3Value = '';
			}
			if (!values.option4Value) {
				errors.option4Value = '';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { addQuizSubmit() },
	});

	const selectAcademicPeriod = (e: any) => {
		setBatchMasterId('');
		setMediumId('');
		setStandardDetailsId('');
		setSectionDetailsId('');
		setSubjectPaperDetailsId('');
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
		getBatchList();
	};

	const selectBatch = (e: any) => {
		setMediumId('');
		setStandardDetailsId('');
		setSubjectPaperDetailsId('');
		let batchMasterId = e;
		setBatchMasterId(batchMasterId);
		getMediumList();
	};
	const selectMedium = (e: any) => {
		setStandardDetailsId('');
		setSubjectPaperDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setSubjectPaperDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (standardDetailsId?.value != undefined) {
			getSubjectPaperList(batchMasterId?.value, standardDetailsId?.value);
		}
	};

	const selectSubject = (e: any) => {
		let subjectPaperDetailsId = e;
		setSubjectPaperDetailsId(subjectPaperDetailsId);
	};

	const ViewQuizList = () => {
		setDataSuccess(true)
		getQuizList(academicPeriodId?.value, batchMasterId?.value, mediumId?.value, standardDetailsId?.value, subjectPaperDetailsId?.value);
	};

	function ViewModal() {
		setAddQuestionModal(true);
	}

	const [quizQuestionsId, setQuizQuestionsId] = useState<any>('')

	function onActivate(i: any, option: any, count: any) {

		if (i != undefined) {
			let selectedQuestion = i;
			setEditQuestionModal(true);
			setQuestionCount(count);
			setQuizQuestionsId(i.quizQuestionsId);

			let correctAnswer = selectedQuestion.correctAnswer == 1 ? '1' : selectedQuestion.correctAnswer == 2 ? '2' : selectedQuestion.correctAnswer == 3 ? '3' : selectedQuestion.correctAnswer == 4 ? '4' : '';
			setCorrectAnswer(correctAnswer);

			editQuestionTypeForm.setValues({
				question: selectedQuestion.question,
				option1Value: option[0].optionValue,
				option2Value: option[1].optionValue,
				option3Value: option[2].optionValue,
				option4Value: option[3].optionValue,
				correctAnswer: correctAnswer,
			});
		}
	}

	const [quizDetailsId, setQuizDetailsId] = useState<any>('')

	function getQuizList(academicPeriodId: any, batchMasterId: any, mediumId: any, standardDetailsId: any, subjectPaperDetailsId: any) {
		setIsLoader(true);
		getQuizDetails(academicPeriodId, batchMasterId, mediumId, standardDetailsId, subjectPaperDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.quizDetails;
					if (data != undefined) {
						setIsLoader(false);
						setQuizData(data);
						setQuizDetailsId(data[0].quizDetailsId);
						setIsOpenListCard(false);
						setDataSuccess(true);

						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						setDataSuccess(true);
						setIsOpenListCard(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setIsOpenListCard(false);
					setDataStatus(true);
					//toasts(response.data.message, "Error")
					setQuizData([]);
					setNoDataMsg(response.data.message);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setAcademicPeriodData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSubjectPaperList(batchMasterId: any, standardDetailsId: any) {
		getSubjectListByStandardDetailsId(
			batchMasterId,
			standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.subjectListByStandardDetailsId;
					if (data != undefined) {
						setSubjectData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setSubjectData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setQuizDetails() {
		return {
			staffDetailsId: userAccountId,
			quizDetailsId: quizDetailsId,
			questions: [
				{
					question: addQuestionTypeForm.values.question,
					option1Value: addQuestionTypeForm.values.option1Value,
					option2Value: addQuestionTypeForm.values.option2Value,
					option3Value: addQuestionTypeForm.values.option3Value,
					option4Value: addQuestionTypeForm.values.option4Value,
					correctAnswer: Number(correctAnswer),
				}
			],
			licenseKey: getLicenseKey,
		};
	}

	function addQuizSubmit() {
		showLoader(true);
		if (addQuestionTypeForm.isValid) {
			let quizDetailsPostData = setQuizDetails();
			addQuizQuestions(
				quizDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getQuizList(academicPeriodId?.value, batchMasterId?.value, mediumId?.value, standardDetailsId?.value, subjectPaperDetailsId?.value)
						closeModal();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addQuestionTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function setEditQuizQuestionDetails() {
		return {
			licenseKey: getLicenseKey,
			quizQuestionsId: quizQuestionsId,
			question: editQuestionTypeForm.values.question,
			option1Value: editQuestionTypeForm.values.option1Value,
			option2Value: editQuestionTypeForm.values.option2Value,
			option3Value: editQuestionTypeForm.values.option3Value,
			option4Value: editQuestionTypeForm.values.option4Value,
			correctAnswer: correctAnswer,
			isActive: 1,
			staffDetailsId: userAccountId,
		};
	}

	function updateQuizQuestionSubmit() {
		showLoader(true);
		if (editQuestionTypeForm.isValid) {
			let quizQuestionData = setEditQuizQuestionDetails();
			updateQuizQuestions(
				quizQuestionData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getQuizList(academicPeriodId?.value, batchMasterId?.value, mediumId?.value, standardDetailsId?.value, subjectPaperDetailsId?.value)
						closeModal();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editQuestionTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteQuizQuestionSubmit() {
		showLoader(true);
		if (quizQuestionsId > 0) {
			let quizQuestionData = setEditQuizQuestionDetails();
			deleteQuizQuestions(quizQuestionData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getQuizList(academicPeriodId?.value, batchMasterId?.value, mediumId?.value, standardDetailsId?.value, subjectPaperDetailsId?.value)
						closeModal();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		}
	}

	function closeModal() {
		setCorrectAnswer('')
		editQuestionTypeForm.resetForm();
		addQuestionTypeForm.resetForm();
		setEditQuestionModal(false);
		setAddQuestionModal(false);
		setIsDeleteOpen(false);
	}

	return (
		<PageWrapper title='Quiz List'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Quiz
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-md-3'>
									<FormGroup
										id='academicPeriodId'
										label='Academic Period'
										isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											placeholder='Academic Period'
											value={academicPeriodId}
											onChange={selectAcademicPeriod}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId,
												label: data.period,
											}))}
											required
										/>
									</FormGroup>
								</div>
								{academicPeriodId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Batch'
												placeholder='Select Batch'
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({
													value: data.batchMasterId,
													label: data.batchName,
												}))}
												required
											/>
										</FormGroup>
									</div>
								) : null}

								{batchMasterId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup id='mediumId' label='Medium' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Medium'
												onChange={selectMedium}
												value={mediumId}
												list={mediumData.map((data: any) => ({
													value: data.mediumId,
													label: data.medium,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{mediumId?.value != undefined ? (
									<div className='col-3'>
										<FormGroup
											id='standardDetailsId'
											label='Standard'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Standard'
												onChange={selectStandard}
												value={standardDetailsId}
												list={standardData.map((data: any) => ({
													value: data.standardDetailsId,
													label: data.standardName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{standardDetailsId?.value != undefined ? (
									<div className='col-3 mt-3'>
										<FormGroup
											id='subjectPaperDetailsId'
											label='Subject'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Subject'
												onChange={selectSubject}
												value={subjectPaperDetailsId}
												list={subjectData.map((data: any) => ({
													value: data.subjectPaperDetailsId,
													label: data.paperName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{subjectPaperDetailsId?.value != undefined ? (
									<div className='col-2 mt-4'>
										<Button
											icon='ArrowDownward'
											color='primary'
											onClick={ViewQuizList}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess ? 
					<>
						{quizData != '' ? 
							<div className='row'>							
									{quizData.map((item: any) => (
										<div className='col-xl-12' key={item.quizDetailsId}>
											<div className='row px-4'>
												<div className='col-3'>
													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-info bg-l${darkModeStatus ? 'o50' : '10'
															}-info-hover transition-base rounded-2 mb-4`}
														shadow='sm'>
														<CardBody>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Book'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-5 mb-0'>
																		{item.standardName} -{' '}
																		{item.medium}
																	</div>
																	<div className='text-muted fs-6'>
																		Standard - Medium
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>

												<div className='col-3'>
													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-info bg-l${darkModeStatus ? 'o50' : '10'
															}-info-hover transition-base rounded-2 mb-4`}
														shadow='sm'>
														<CardBody>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='AutoStories'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-5 mb-0'>
																		{item.paperName}
																	</div>
																	<div className='text-muted fs-6'>
																		subject
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>

												<div className='col-3'>
													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-info bg-l${darkModeStatus ? 'o50' : '10'
															}-info-hover transition-base rounded-2 mb-4`}
														shadow='sm'>
														<CardBody>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='BorderColor'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-5 mb-0'>
																		{item.titleName}
																	</div>
																	<div className='text-muted fs-6'>
																		Title
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>

												<div className='col-md-3'>
													<Card stretch>
														<Button
															color='info'
															size='lg'
															isLight
															className='w-100 h-100'
															icon='AddCircle'
															onClick={ViewModal}>
															Add New Question
														</Button>
													</Card>
												</div>
											</div>
										</div>
									))}
									{quizData.map((item: any) => (
										<>
											{item.questions.map((i: any, index: any) => (
												<div className="row" key={index}>
													<div className="col-12">
														<Card shadow={'md'} className='border border-light'>
															<CardHeader>
																<CardLabel className="col-lg-10">
																	<CardTitle tag='div' className='h5 text-muted'>{index + 1} . {i.question}</CardTitle>
																</CardLabel>
																<CardActions>
																	<Button
																		color='dark'
																		isLight
																		icon='Edit'
																		aria-label='Edit'
																		onClick={() => onActivate(i, i.optionArray, index + 1)}
																	/>
																</CardActions>
															</CardHeader>
															<CardBody>
																<div className='col-12'>
																	<div style={{ marginTop: -30 }} className='row'>
																		{i.optionArray.map(
																			(option: any, index1: any) => (
																				<div className='col-6' key={index1}>
																					<Label className='fs-6'>Option {index1+1}</Label>
																					<Button
																						isDisable={i.correctAnswer != option.optionId}
																						isLight={i.correctAnswer != option.optionId}
																						icon={i.correctAnswer == option.optionId ? 'TaskAlt' : ''}
																						color={i.correctAnswer == option.optionId ? 'success' : 'info'}
																						className='w-100 p-2 mt-1 rounded-1'>
																						{option.optionValue}
																					</Button>
																				</div>
																			),
																		)}
																	</div>

																</div>
															</CardBody>
														</Card>
													</div>
												</div>
											))}
										</>
									))}
								</div> :
							<div className='row'>
								<div className='col-12' style={{ height: '20vh' }}></div>
								<div className='col-12 d-flex justify-content-center'>
									<Icon icon='DoNotDisturb' size={'10x'} />
								</div>
								<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
									{noDataMsg}
								</div>
							</div>
						}
					</>
				 : null}
			</Page>

			<Modal
				setIsOpen={setAddQuestionModal}
				isOpen={addQuestionModal}
				titleId='addTempStudent'
				isCentered
				isStaticBackdrop
				size='xl'>
				<ModalHeader
					className="'modal-header'"
					setIsOpen={() => {
						setAddQuestionModal(false);
					}}>
					<ModalTitle id='addTempStudent'>Add Question</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row px-4'>
						<div className='col-xl-8'>
							<Card stretch shadow={'md'} className='border border-light'>
								<CardHeader borderSize={1}>
									<CardLabel icon='EditNote' color='primary'>
										<CardTitle>Question {questionCount}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4 mt-1'>
										<div className='col-12 '>
											<p>
												<FormGroup
													id='question'
													label='Question'
													isFloating>
													<Textarea
														rows={6}
														onChange={addQuestionTypeForm.handleChange}
														onBlur={addQuestionTypeForm.handleBlur}
														isValid={addQuestionTypeForm.isValid}
														isTouched={addQuestionTypeForm.touched.question}
														invalidFeedback={addQuestionTypeForm.errors.question}
														value={addQuestionTypeForm.values.question}
														style={{ height: '80px', width: '100%' }}
														placeholder='Enter Question'
													/>
												</FormGroup>
											</p>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option1Value'
												label='Option 1'
												isFloating>
												<Input
													placeholder='Enter Option A'
													onChange={addQuestionTypeForm.handleChange}
													onBlur={addQuestionTypeForm.handleBlur}
													isValid={addQuestionTypeForm.isValid}
													isTouched={addQuestionTypeForm.touched.option1Value}
													invalidFeedback={addQuestionTypeForm.errors.option1Value}
													value={addQuestionTypeForm.values.option1Value}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option2Value'
												label='Option 2'
												isFloating>
												<Input
													placeholder='Enter Option B'
													onChange={addQuestionTypeForm.handleChange}
													onBlur={addQuestionTypeForm.handleBlur}
													isValid={addQuestionTypeForm.isValid}
													isTouched={addQuestionTypeForm.touched.option2Value}
													invalidFeedback={addQuestionTypeForm.errors.option2Value}
													value={addQuestionTypeForm.values.option2Value}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option3Value'
												label=' Option 3'
												isFloating>
												<Input
													placeholder=' Option 3'
													onChange={addQuestionTypeForm.handleChange}
													onBlur={addQuestionTypeForm.handleBlur}
													isValid={addQuestionTypeForm.isValid}
													isTouched={addQuestionTypeForm.touched.option3Value}
													invalidFeedback={addQuestionTypeForm.errors.option3Value}
													value={addQuestionTypeForm.values.option3Value}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option4Value'
												label=' Option 4'
												isFloating>
												<Input
													placeholder='Enter Option D'
													onChange={addQuestionTypeForm.handleChange}
													onBlur={addQuestionTypeForm.handleBlur}
													isValid={addQuestionTypeForm.isValid}
													isTouched={addQuestionTypeForm.touched.option4Value}
													invalidFeedback={addQuestionTypeForm.errors.option4Value}
													value={addQuestionTypeForm.values.option4Value}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-4'>
							<Card stretch shadow={'md'} className='border border-light'>
								<CardHeader borderSize={1}>
									<CardLabel icon='CheckCircleOutline' color='primary'>
										<CardTitle>Correct Answer</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup>
										<ChecksGroup>
											<br />
											<FormGroup id='correctAnswer'>
												<Checks
													type='radio'
													label='Option 1'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('1')}
													checked={correctAnswer == '1' ? '1' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option2Value'>
												<Checks
													type='radio'
													label='Option 2'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('2')}
													checked={correctAnswer == '2' ? '2' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option3Value'>
												<Checks
													type='radio'
													label='Option 3'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('3')}
													checked={correctAnswer == '3' ? '3' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option4Value'>
												<Checks
													type='radio'
													label='Option 4'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('4')}
													checked={correctAnswer == '4' ? '4' : false}
												/>
											</FormGroup>
										</ChecksGroup>
									</FormGroup>
								</CardBody>
								<br />
								<br />
								<br />
							</Card>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='info' isOutline className='border-0' onClick={closeModal}>
						Close
					</Button>
					<Button color='info' icon='Save' type='submit' onClick={addQuestionTypeForm.handleSubmit}>
						Submit
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				setIsOpen={setEditQuestionModal}
				isOpen={editQuestionModal}
				titleId='addTempStudent'
				isCentered
				isStaticBackdrop
				size='xl'>
				<ModalHeader
					className="'modal-header'"
					setIsOpen={() => { setEditQuestionModal(false); closeModal() }}>
					<ModalTitle id='addTempStudent'>Update Question</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row px-4'>
						<div className='col-xl-8'>
							<Card stretch shadow={'md'} className='border border-light'>
								<CardHeader borderSize={1}>
									<CardLabel icon='EditNote' color='primary'>
										<CardTitle>Question {questionCount}</CardTitle>
									</CardLabel>
								</CardHeader>

								<CardBody>
									<div className='row g-4 mt-1'>
										<div className='col-12 '>
											<p>
												<FormGroup
													id='question'
													label='Question'
													isFloating>
													<Textarea
														rows={6}
														onChange={
															editQuestionTypeForm.handleChange
														}
														onBlur={editQuestionTypeForm.handleBlur}
														isValid={editQuestionTypeForm.isValid}
														isTouched={
															editQuestionTypeForm.touched
																.question
														}
														invalidFeedback={
															editQuestionTypeForm.errors.question
														}
														value={
															editQuestionTypeForm.values.question
														}
														style={{
															height: '80px',
															width: '100%',
														}}
														placeholder='Enter Question'
													/>
												</FormGroup>
											</p>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option1Value'
												label='Option 1'
												isFloating>
												<Input
													placeholder='Enter Option A'
													onChange={editQuestionTypeForm.handleChange}
													onBlur={editQuestionTypeForm.handleBlur}
													isValid={editQuestionTypeForm.isValid}
													isTouched={
														editQuestionTypeForm.touched
															.option1Value
													}
													invalidFeedback={
														editQuestionTypeForm.errors.option1Value
													}
													value={
														editQuestionTypeForm.values.option1Value
													}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option2Value'
												label='Option 2'
												isFloating>
												<Input
													placeholder='Enter Option B'
													onChange={editQuestionTypeForm.handleChange}
													onBlur={editQuestionTypeForm.handleBlur}
													isValid={editQuestionTypeForm.isValid}
													isTouched={
														editQuestionTypeForm.touched
															.option2Value
													}
													invalidFeedback={
														editQuestionTypeForm.errors.option2Value
													}
													value={
														editQuestionTypeForm.values.option2Value
													}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option3Value'
												label=' Option 3'
												isFloating>
												<Input
													placeholder=' Option 3'
													onChange={editQuestionTypeForm.handleChange}
													onBlur={editQuestionTypeForm.handleBlur}
													isValid={editQuestionTypeForm.isValid}
													isTouched={
														editQuestionTypeForm.touched
															.option3Value
													}
													invalidFeedback={
														editQuestionTypeForm.errors.option3Value
													}
													value={
														editQuestionTypeForm.values.option3Value
													}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='option4Value'
												label=' Option 4'
												isFloating>
												<Input
													placeholder='Enter Option D'
													onChange={editQuestionTypeForm.handleChange}
													onBlur={editQuestionTypeForm.handleBlur}
													isValid={editQuestionTypeForm.isValid}
													isTouched={
														editQuestionTypeForm.touched
															.option4Value
													}
													invalidFeedback={
														editQuestionTypeForm.errors.option4Value
													}
													value={
														editQuestionTypeForm.values.option4Value
													}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-4'>
							<Card stretch shadow={'md'} className='border border-light'>
								<CardHeader borderSize={1}>
									<CardLabel icon='CheckCircleOutline' color='primary'>
										<CardTitle>Correct Answer</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<FormGroup>
										<ChecksGroup>
											<br />
											<FormGroup id='correctAnswer'>
												<Checks
													type='radio'
													label='Option 1'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('1')}
													checked={correctAnswer == '1' ? '1' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option2Value'>
												<Checks
													type='radio'
													label='Option 2'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('2')}
													checked={correctAnswer == '2' ? '2' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option3Value'>
												<Checks
													type='radio'
													label='Option 3'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('3')}
													checked={correctAnswer == '3' ? '3' : false}
												/>
											</FormGroup>
											<br />
											<FormGroup id='option4Value'>
												<Checks
													type='radio'
													label='Option 4'
													value={correctAnswer}
													onChange={() => setCorrectAnswer('4')}
													checked={correctAnswer == '4' ? '4' : false}
												/>
											</FormGroup>
										</ChecksGroup>
									</FormGroup>
								</CardBody>
								<br />
								<br />
								<br />
							</Card>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='info' isOutline className='border-0' onClick={closeModal}>
						Close
					</Button>
					<Button
						color='danger'
						icon='Delete'
						onClick={() => {
							setIsDeleteOpen(!isDeleteOpen);
							setEditQuestionModal(false);
						}}>
						Delete
					</Button>
					<Button color='info' icon='Save' type='submit' onClick={editQuestionTypeForm.handleSubmit}>
						Update
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				setIsOpen={setIsDeleteOpen}
				isOpen={isDeleteOpen}
				titleId='deleteBatchMaster'
				isStaticBackdrop
				size='sm'>
				<ModalHeader>
					<></>
				</ModalHeader>
				<ModalBody>
					<div className='row g-2'>
						<div className='col-12 d-flex justify-content-center'>
							<h5>
								<strong>Are you sure want to delete ?</strong>
							</h5>
						</div>
						<div className='col-10'>
							<div className='d-flex gap-2 justify-content-end'>
								<Button
									color='danger'
									size={'sm'}
									onClick={deleteQuizQuestionSubmit}>
									Yes
								</Button>
								<Button
									color='info'
									size={'sm'}
									onClick={() => {
										setIsDeleteOpen(!isDeleteOpen);
										setEditQuestionModal(true);
									}}>
									No
								</Button>
							</div>
						</div>
						<div className='col-10'></div>
					</div>
				</ModalBody>
			</Modal>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

		</PageWrapper>
	);
};

export default QuizList;
