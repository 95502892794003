import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getExamType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamType - " + error.message)
            console.log('error caught in service : getExamType')
        },
    );

export const getExamDetails = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamDetails/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamDetails - " + error.message)
            console.log('error caught in service : getExamDetails')
        },
    );

export const addExamTimeTable = (examTimeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExamTimeTable`, examTimeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExamTimeTable')
        }
    );

export const addExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addExamDetails');
        },
    );

export const updateExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateExamDetails');
        },
    );

export const deleteExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteExamDetails');
        },
    );

export const getExamHall = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamHall/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamHall - " + error.message)
            console.log('error caught in service : getExamHall')
        },
    );

export const getExamTimeTable = (academicPeriodId: any, examTypeId: any, standardDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamTimeTable/${getLicenseKey}/${academicPeriodId}/${examTypeId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamTimeTable - " + error.message)
            console.log('error caught in service : getExamTimeTable')
        },
    );

export const getExamHallAllocation = (examDetailsId: any, examHallDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamHallAllocation/${getLicenseKey}/${examDetailsId}/${examHallDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamHallAllocation - " + error.message)
            console.log('error caught in service : getExamHallAllocation')
        },
    );

export const addExamHallAllocationDetails = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addExamHallAllocation');
        },
    );

export const updateExamHallAllocation = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateExamHallAllocation');
        },
    );

export const deleteExamHallAllocation = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteExamHallAllocation');
        },
    );

export const getClassWiseMarkList = (batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, examDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getClassWiseMarkList/${getLicenseKey}/${batchMasterId}/${standardDetailsId}/${sectionDetailsId}/${examDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getClassWiseMarkList - " + error.message)
            console.log('error caught in service : getClassWiseMarkList')
        },
    );

export const getStudentExamMark = (standardDetailsId: any, sectionDetailsId: any, studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentExamMarkDetails/${getLicenseKey}/${standardDetailsId}/${sectionDetailsId}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentExamMarkDetails - " + error.message)
            console.log('error caught in service : getStudentExamMarkDetails')
        },
    );

export const getStudentExamMarkDetails = (StandardDetailsId: any, SectionDetailsId: any, StudentDetailsId: any, examDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentExamMarkDetails/${getLicenseKey}/${StandardDetailsId}/${SectionDetailsId}/${StudentDetailsId}/${examDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentExamMarkDetails - " + error.message)
            console.log('error caught in service : getStudentExamMarkDetails')
        },
    );

export const addStudentMarkDetails = (studentMarkPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addStudentMarkDetails`, studentMarkPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addStudentMarkDetails');
        },
    );

export const addQuizDetails = (quizDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addQuizDetails`, quizDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addQuizDetails');
        },
    );

export const getQuizDetails = (academicPeriodId: any, batchMasterId: any, mediumId: any, standardDetailsId: any, subjectPaperDetailsId:any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getQuizDetails/${getLicenseKey}/${academicPeriodId}/${batchMasterId}/${mediumId}/${standardDetailsId}/${subjectPaperDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getQuizDetails - " + error.message)
            console.log('error caught in service : getQuizDetails')
        },
    );

export const getSubjectListByStandardDetailsId = (batchMasterId: any, standardDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSubjectListByStandardDetailsId/${getLicenseKey}/${batchMasterId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSubjectListByStandardDetailsId - " + error.message)
            console.log('error caught in service : getSubjectListByStandardDetailsId')
        },
    );

export const getClassWiseQuizMarkList = (batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, quizDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getClassWiseQuizMarkList/${getLicenseKey}/${batchMasterId}/${standardDetailsId}/${sectionDetailsId}/${quizDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getClassWiseQuizMarkList - " + error.message)
            console.log('error caught in service : getClassWiseQuizMarkList')
        },
    );

export const updateQuizQuestions = (quizQuestionData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateQuizQuestions`, quizQuestionData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateQuizQuestions');
        },
    );

export const deleteQuizQuestions = (quizQuestionData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteQuizQuestions`, quizQuestionData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteQuizQuestions');
        },
    );

export const addQuizQuestions = (quizDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addQuizQuestions`, quizDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addQuizQuestions');
        },
    );
