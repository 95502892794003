import { useFormik } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import {
	getColumnsForDataTable,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { TableLoader } from '../../../services/loader.services';
import NoDataMsg from '../../../common/components/NoDataMsg';
import { getComplaintDetails } from '../../../services/student.service';
import data from '../../../common/data/dummyEventsData';
import USERS from '../../../common/data/userDummyData';
import { priceFormat } from '../../../helpers/helpers';
import SubHeader, {
	SubHeaderLeft,
	SubheaderSeparator,
	SubHeaderRight,
} from '../../../layout/SubHeader/SubHeader';
import { demoPagesMenu } from '../../../menu';
import { TABS } from '../../../pages/presentation/dashboard/common/helper';
import Avatar from '../../Avatar';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';
import Chart from '../../extras/Chart';
import PlaceholderImage from '../../extras/PlaceholderImage';

function ComplaintList() {
	useEffect(() => {
		getComplaintList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [complaintDetailsData, setComplaintDetailsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [studentComplaintsData, setStudentComplaintsData] = useState([]);
	const [staffComplaintsData, setStaffComplaintsData] = useState([]);

	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState<any>('');

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(complaintDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	type TTabs = 'Comments';
	interface ITabs {
		[key: string]: TTabs;
	}

	const TABS: ITabs = {
		COMMENTS: 'Comments',
	};
	const [activeTab, setActiveTab] = useState(TABS.SUMMARY);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function getComplaintList() {
		setDataStatus(false);
		getComplaintDetails(
			0,
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintDetails;
					if (data != undefined) {
						getColumnsForTable('getComplaintDetails', 'get');
						setComplaintDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						let studentComplaints = data.filter((item: any) => item.userTypeId == 9);
						setStudentComplaintsData(studentComplaints);

						let staffComplaints = data.filter((item: any) => item.userTypeId != 9);
						setStaffComplaintsData(staffComplaints);
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getComplaintDetails', 'get');
					setComplaintDetailsData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getComplaintDetails', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Complaint List'>
			<Page>
				<div className='row h-100'>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Student Complaints
									</CardTitle>
									{/* <CardSubTitle tag='div' className='h6'>
                                        {i.studentRollNoOrEmpNumber}
                                    </CardSubTitle> */}
								</CardLabel>
							</CardHeader>

							<CardBody isScrollable>
								{studentComplaintsData.map((i: any, index: any) => (
									<div className={index > 0 ? 'row g-4 mt-1' : 'row g-4'} key={i.complaintDetailsId}>
										<div className='col-12 d-flex'>
											<div className='flex-shrink-0'>
												{i.profilePath != null ? (
													<Avatar src={i.profilePath} size={64} />
												) : (
													<img
														src={profilePic(i.genderId)}
														width='64'
														height='64'
													/>
												)}
											</div>
											<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
												<figure className='mb-0'>
													<div className='text-primary'>
														{' '}
														{i.studentOrStaffName} -{' '}
														<strong className='text-dark'>
															{i.studentRollNoOrEmpNumber}
														</strong>
													</div>
													<div className='text-primary fw-bold'><u>{i.complaintType}</u></div>
													<blockquote className='blockquote'>
														<div className='fs-6'
															dangerouslySetInnerHTML={{
																__html: i.natureOfComplaint,
															}}
														/>
													</blockquote>
													<figcaption className='blockquote-footer mb-0'>
														{i.complaintDateForView}
													</figcaption>
												</figure>
											</div>
										</div>
										<div className="col-12 mt-2 border border-light"></div>
									</div>
								))}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card
							stretch
							className='overflow-hidden border border-light'
							shadow='md'
							tag='form'
							noValidate>
							<CardHeader borderSize={1}>
								<CardLabel icon='Chat' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Staff Complaints
									</CardTitle>
									{/* <CardSubTitle tag='div' className='h6'>
												{i.studentRollNoOrEmpNumber}
											</CardSubTitle> */}
								</CardLabel>
							</CardHeader>

							<CardBody isScrollable>
								{staffComplaintsData.map((i: any, index: any) => (
									<div className={index > 0 ? 'row g-4 mt-1' : 'row g-4'} key={i.complaintDetailsId}>
										<div className='col-12 d-flex'>
											<div className='flex-shrink-0'>
												{i.profilePath != null ? (
													<Avatar src={i.profilePath} size={64} />
												) : (
													<img
														src={profilePic(i.genderId)}
														width='64'
														height='64'
													/>
												)}
											</div>
											<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
												<figure className='mb-0'>
													<div className='text-primary'>
														{' '}
														{i.studentOrStaffName} -{' '}
														<strong className='text-dark'>
															{i.studentRollNoOrEmpNumber}
														</strong>
													</div>
													<div className='text-primary fw-bold'><u>{i.complaintType}</u></div>
													<blockquote className='blockquote'>
														<div className='fs-6'
															dangerouslySetInnerHTML={{
																__html: i.natureOfComplaint,
															}}
														/>
													</blockquote>
													<figcaption className='blockquote-footer mb-0'>
														{i.complaintDateForView}
													</figcaption>
												</figure>
											</div>
										</div>
										<div className="col-12 mt-2 border border-light"></div>
									</div>
								))}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
}
export default ComplaintList;
