import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Collapse from "../../../bootstrap/Collapse";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Button from "../../../bootstrap/Button";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import AlertService from "../../../../services/AlertService";
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getMedium, getStandardDetails, updateColumnsForDataTable } from "../../../../services/common.service";
import { toasts } from "../../../../services/toast.service";
import { addTodayHomeWorkDetails, getBatchMasterDetails, getHomeWorkListByClassWise, getSectionDetails } from "../../../../services/master.service";
import Input from "../../../bootstrap/forms/Input";
import { useFormik } from "formik";
import useSortableData from "../../../../hooks/useSortableData";
import { dataPagination } from "../../../PaginationButtons";
import { getLicenseKey } from "../../../../services/application.settings";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import { getSubjectListByStandardDetailsId } from "../../../../services/exam.service";
import Textarea from "../../../bootstrap/forms/Textarea";
import { showLoader } from "../../../../services/loader.services";

function HomeWork() {

    useEffect(() => {
        getBatchList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [homeWorkDetailsData, setHomeWorkDetailsData] = useState<any>([])
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumId, setMediumId] = useState<any>('')
    const [mediumData, setMediumData] = useState<any>([])
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [dateOfAttendance, setDateOfAttendance] = useState<any>([])

    const [studentSchoolSubjectData, setStudentSchoolSubjectData] = useState<any>([])

    const [homeWork1, setHomeWork1] = useState<any>('')
    const [homeWork2, setHomeWork2] = useState<any>('')
    const [homeWork3, setHomeWork3] = useState<any>('')
    const [homeWork4, setHomeWork4] = useState<any>('')
    const [homeWork5, setHomeWork5] = useState<any>('')
    const [homeWork6, setHomeWork6] = useState<any>('')

    const [dataSuccess, setDataSuccess] = useState(false)

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false)

    const homeWorkForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            homeWork1: "",
            homeWork1Description: "",
            homeWork2: "",
            homeWork2Description: "",
            homeWork3: "",
            homeWork3Description: "",
            homeWork4: "",
            homeWork4Description: "",
            homeWork5: "",
            homeWork5Description: "",
            homeWork6: "",
            homeWork6Description: "",
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectBatch = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        setMediumId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        getMediumList();
    }

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        getStandardList()
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionDetailsList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function viewHomeWork() {
        emptyForm()
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getSubjectList(batchMasterId?.value, standardDetailsId?.value)
        getHomeWorkList(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value)
    }

    function getSubjectList(batchMasterId: any, standardDetailsId: any) {
        getSubjectListByStandardDetailsId(batchMasterId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectListByStandardDetailsId;
                    if (data != undefined) {
                        setStudentSchoolSubjectData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);

                    } else {
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataSuccess(true);
                    setStudentSchoolSubjectData([]);
                    setIsOpenListCard(false);
                    setIsLoader(false);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getHomeWorkList(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any) {
        getHomeWorkListByClassWise(batchMasterId, standardDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.homeWorkListByClassWise;
                    if (data != undefined) {
                        setHomeWorkDetailsData(data);
                        const matchingEntry = data.find((item: any) => {
                            return item.dateOfHomeWork !== undefined && item.dateOfHomeWork === dateOfAttendance;
                        }) || '';                      

                        if (matchingEntry != '') {
                            setHomeWork1(matchingEntry.homeWorkDetails[0].homeWork != '' ? { value: matchingEntry.homeWorkDetails[0].homeWork, label: matchingEntry.homeWorkDetails[0].homeWork } : '')
                            setHomeWork2(matchingEntry.homeWorkDetails[1].homeWork != '' ? { value: matchingEntry.homeWorkDetails[1].homeWork, label: matchingEntry.homeWorkDetails[1].homeWork } : '')
                            setHomeWork3(matchingEntry.homeWorkDetails[2].homeWork != '' ? { value: matchingEntry.homeWorkDetails[2].homeWork, label: matchingEntry.homeWorkDetails[2].homeWork } : '')
                            setHomeWork4(matchingEntry.homeWorkDetails[3].homeWork != '' ? { value: matchingEntry.homeWorkDetails[3].homeWork, label: matchingEntry.homeWorkDetails[3].homeWork } : '')
                            setHomeWork5(matchingEntry.homeWorkDetails[4].homeWork != '' ? { value: matchingEntry.homeWorkDetails[4].homeWork, label: matchingEntry.homeWorkDetails[4].homeWork } : '')
                            setHomeWork6(matchingEntry.homeWorkDetails[4].homeWork != '' ? { value: matchingEntry.homeWorkDetails[5].homeWork, label: matchingEntry.homeWorkDetails[5].homeWork } : '')

                            homeWorkForm.setValues({
                                homeWork1: homeWork1?.label != '' ? homeWork1?.label : '',
                                homeWork1Description: matchingEntry.homeWorkDetails[0]?.homeWorkDescription || "",
                                homeWork2: homeWork2?.label != '' ? homeWork2?.label : '',
                                homeWork2Description: matchingEntry.homeWorkDetails[1]?.homeWorkDescription || "",
                                homeWork3: homeWork3?.label != '' ? homeWork3?.label : '',
                                homeWork3Description: matchingEntry.homeWorkDetails[2]?.homeWorkDescription || "",
                                homeWork4: homeWork4?.label != '' ? homeWork4?.label : '',
                                homeWork4Description: matchingEntry.homeWorkDetails[3]?.homeWorkDescription || "",
                                homeWork5: homeWork5?.label != '' ? homeWork5?.label : '',
                                homeWork5Description: matchingEntry.homeWorkDetails[4]?.homeWorkDescription || "",
                                homeWork6: homeWork6?.label != '' ? homeWork6?.label : '',
                                homeWork6Description: matchingEntry.homeWorkDetails[5]?.homeWorkDescription || "",
                            });
                        }


                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setHomeWorkDetailsData([]);
                    getSubjectList(batchMasterId, standardDetailsId)
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionDetailsList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setExamTimeTableDetails() {
        return ({
            licenseKey: getLicenseKey,
            batchMasterId: batchMasterId?.value,
            standardDetailsId: standardDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            dateOfHomeWork: dateOfAttendance,

            homeWork1: homeWork1?.label != undefined ? homeWork1?.label : '',
            homeWork1Description: homeWorkForm.values.homeWork1Description,
            homeWork2: homeWork2?.label != undefined ? homeWork2?.label : '',
            homeWork2Description: homeWorkForm.values.homeWork2Description,
            homeWork3: homeWork3?.label != undefined ? homeWork3?.label : '',
            homeWork3Description: homeWorkForm.values.homeWork3Description,
            homeWork4: homeWork4?.label != undefined ? homeWork4?.label : '',
            homeWork4Description: homeWorkForm.values.homeWork4Description,
            homeWork5: homeWork5?.label != undefined ? homeWork5?.label : '',
            homeWork5Description: homeWorkForm.values.homeWork5Description,
            homeWork6: homeWork6?.label != undefined ? homeWork6?.label : '',
            homeWork6Description: homeWorkForm.values.homeWork6Description,
            staffDetailsId: userAccountId
        })
    }

    function addExamTimeTableSubmit() {
        showLoader(true);
        // if (subjectCount == examTimeTable.length) {
        let examTimeTablePostData = setExamTimeTableDetails();
        console.log(examTimeTablePostData);

        addTodayHomeWorkDetails(examTimeTablePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    homeWorkForm.resetForm();
                    emptyForm();
                    getHomeWorkList(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value)
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )

        // } else if (subjectCount != examTimeTable.length) {
        //     showLoader(false);
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function emptyForm() {
        homeWorkForm.resetForm()
        setHomeWork1('')
        setHomeWork2('')
        setHomeWork3('')
        setHomeWork4('')
        setHomeWork5('')
        setHomeWork6('')
    }

    return (
        <PageWrapper title='Student List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Home Work</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
                                    </FormGroup>
                                </div>
                                {batchMasterId?.value != undefined ?
                                    <div className='col-md-2'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Medium' placeholder="Select Standard"
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                        </FormGroup>
                                    </div> : null
                                }
                                {mediumId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                            onChange={selectStandard}
                                            value={standardDetailsId}
                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />
                                    </FormGroup>
                                </div> : null}
                                {standardDetailsId?.value != undefined ? <div className='col-md-2'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}
                                {sectionDetailsId?.value != undefined ? <div className="col-md-2">
                                    <FormGroup id='dateOfAttendance' label='Date' isFloating>
                                        <Input onChange={(e: any) => { setDateOfAttendance(e.target.value) }} value={dateOfAttendance}
                                            type='date' />
                                    </FormGroup>
                                </div> : null}
                                {dateOfAttendance != '' ? <div className="col-md-2">
                                    <Button className='mt-2' icon='ArrowDownward' color='primary' isDisable={sectionDetailsId?.value == undefined} onClick={() => { viewHomeWork(); }} >View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='list' iconColor='primary'>
                                <CardTitle>
                                    Home Work Details
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody >
                            <form noValidate onSubmit={homeWorkForm.handleSubmit}>
                                <div className="d-grid gap-4">
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork1' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork1(e) }}
                                                    value={homeWork1}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork1Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork1Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork2' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork2(e) }}
                                                    value={homeWork2}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork2Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork2Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork3' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork3(e) }}
                                                    value={homeWork3}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork3Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork3Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork4' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork4(e) }}
                                                    value={homeWork4}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork4Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork4Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork5' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork5(e) }}
                                                    value={homeWork5}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork5Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork5Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-lg-3" >
                                            <FormGroup id='homeWork6' label="Subject" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Subject"
                                                    onChange={(e) => { setHomeWork6(e) }}
                                                    value={homeWork6}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.subjectPaperDetailsId, label: data.paperName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-9" >
                                            <FormGroup id="homeWork6Description" label="Enter Home Work" isFloating>
                                                <Textarea rows={5}
                                                    onChange={homeWorkForm.handleChange}
                                                    value={homeWorkForm.values.homeWork6Description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-4">
                                    <div>
                                        <Button icon="Save" color="info" type="submit" onClick={addExamTimeTableSubmit}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>

                        </CardBody>
                    </Card>
                    : null}

            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    );
}

export default HomeWork;