import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getComplaintType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getComplaintType/${getLicenseKey}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getComplaintType - ' + error.message);
		console.log('error caught in service : getComplaintType');
	},
);

export const addComplaintDetails = (complaintData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `addComplaintDetails`, complaintData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : addComplaintDetails')
		}
	);