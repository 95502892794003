import { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import Icon from '../../icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import { useFormik } from 'formik';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import { getClassCapacityReport } from '../../../services/report.service';
import AuthContext from '../../../contexts/authContext';
import {
	getColumnsForDataTable,
	getGraduationType,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import { getLicenseKey } from '../../../services/application.settings';
import { getAcademicPeriod } from '../../../services/master.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

function ClassCapacityReport() {

	useEffect(() => {
		getAcademicPeriodList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [courseCapacityReportData, setCourseCapacityReportData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(courseCapacityReportData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		courseCapacityReportData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	const selectAcademicPeriod = (e: any) => {
		setIsOpenListCard(true);
		setDataSuccess(false);
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
	};

	function view() {
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		getClassCapacity(academicPeriodId?.value);
	}

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAcademicPeriodData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [report, setReport] = useState<any>([])
	const [report1, setReport1] = useState<any>([])
	const [particularDate, setParticularDate] = useState('')
	const [admission, setAdmission] = useState('')

	function getClassCapacity(academicPeriodId: string) {
		setIsLoader(true);
		getClassCapacityReport(academicPeriodId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.classCapacityReport;
					if (data != undefined) {
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						getColumnsForTable('getClassCapacityReport', 'get');
						setCourseCapacityReportData(data);

						let uniqueData: any[] = [];
						let uniqueData1 = [];
						let courseIds = new Set();
						let dateOfAdmissions = new Set();

						for (let i = 0; i < data.length; i++) {
							let particular = data[i].dateOfAdmission
							let ad = data[i].admissionCount
							setParticularDate(particular)
							setAdmission(ad)
						}

						for (let i = 0; i < data.length; i++) {
							if (!dateOfAdmissions.has(data[i].dateOfAdmission)) {
								dateOfAdmissions.add(data[i].dateOfAdmission);
								uniqueData.push(data[i]);
							}
						}

						for (let i = 0; i < data.length; i++) {
							if (!courseIds.has(data[i].standardDetailsId)) {
								courseIds.add(data[i].standardDetailsId);
								uniqueData1.push(data[i]);
							}
						}
						for (let i = 0; i < uniqueData1.length; i++) {
							uniqueData1[i].sno = i + 1;
						}
						setReport(uniqueData);
						setReport1(uniqueData1);
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getClassCapacityReport', 'get');
					setCourseCapacityReportData([]);
					setNoDataMsg(response.data.message);
					setIsOpenListCard(false);
					setDataSuccess(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getClassCapacityReport', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Class Capacity Report'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									OVER ALL ADMISSION ABSTRACT
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-lg-3'>
									<FormGroup
										id='academicPeriod'
										label='Academic Period'
										isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Academic Period'
											onChange={selectAcademicPeriod}
											value={academicPeriodId}
											list={academicPeriodData.map((data: any) => ({
												value: data.academicPeriodId,
												label: data.period,
											}))}
										/>
									</FormGroup>
								</div>
								{academicPeriodId?.value != undefined ?
									<div className='col-3 align-self-center'>
										<Button icon='ArrowDownward' color='primary' onClick={view}>
											View
										</Button>
									</div>
									: null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-7'>
								<CardTitle tag='div' className='h5'>
									OVER ALL ADMISSION ABSTRACT
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ButtonGroup
								className='col-lg-3 justify-content-end d-print-none'
								color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>Export</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload'
												onClick={() => downloadExcel('CourseCapacityReport', courseCapacityReportData, columnVisibilityData,)}
											> Excel
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='PictureAsPdf'
												onClick={() => convertJsonToPdf(courseCapacityReportData, columnVisibilityData, 'CourseCapacityReport',)}
											> PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload'
												onClick={() => downloadFile('CourseCapacityReport', courseCapacityReportData, columnVisibilityData,)}
											> CSV
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr className='table-success text-decoration-underline mt-4'>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										<th
											scope='col'
											onClick={() => requestSort('standardName')}
											className='cursor-pointer text-decoration-underline'>
											Standard{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('standardName')}
												icon='FilterList'
											/>
										</th>
										<th
											scope='col'
											onClick={() => requestSort('totalAllocated')}
											className='cursor-pointer text-decoration-underline'>
											Capacity{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('totalAllocated')}
												icon='FilterList'
											/>
										</th>
										{report.map((rowData2: any) => (
											<th
												key={rowData2.keyName}
												scope='col'
												onClick={() => requestSort(rowData2.dateOfAdmission)}
												className='cursor-pointer text-decoration-underline'>
												{rowData2.dateOfAdmission}
												<Icon
													size='lg'
													className={getClassNamesFor(rowData2.dateOfAdmission)}
													icon='FilterList'
												/>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{filteredData != '' ? (
										<>
											{report1.map((rowData: any) => (
												<tr key={rowData.standardDetailsId}>
													<td>{rowData.sno}</td>
													<td>{rowData.standardName}</td>
													<td>{rowData.totalAllocated}</td>
													{report.map((dateOfAdmissionData: any) => {
														const admissionData = courseCapacityReportData.find(
															(rowData1: any) => rowData1.standardDetailsId === rowData.standardDetailsId && rowData1.dateOfAdmission === dateOfAdmissionData.dateOfAdmission
														);
														return (
															<td key={dateOfAdmissionData}>
																{admissionData ? admissionData.admissionCount : 0}
															</td>
														);
													})}

													{/* 
													// old code
													{courseCapacityReportData.map((rowData1: any) => (
														<>
														{report.find((dateOfaddmission: any) => (

														<td>
															{rowData1.dateOfAdmission === dateOfaddmission.dateOfAdmission  ? rowData1.admissionCount : 0}</td>))}
														</>
														))} */}

													{/* {columnVisibilityData.map((column: any) =>
														column.isDisplay &&
															rowData[column.keyName] ? (
															<td
																key={`${rowData.standardDetailsId}-${column.keyName}`}>
																{rowData[column.keyName]}
															</td>
														) : (
															<td key={`empty-${column.keyName}`} />
														),
													)} */}
												</tr>
											))}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 1}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						</CardBody>
						{/* <PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/> */}
					</Card>
				) : null}
			</Page>
		</PageWrapper>
	);
}
export default ClassCapacityReport;
