import React, { useContext, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Page from '../../layout/Page/Page';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import { profilePic } from '../../services/common.service';
import { useFormik } from 'formik';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import { showLoader } from '../../services/loader.services';
import { addEventDetails } from '../../services/sportsManagement.service';
import AlertService from '../../services/AlertService';

const EventDetails = () => {

	const { userAccountId } = useContext(AuthContext);
	const [selectedFile1, setSelectedFile1] = useState<any>('');
	const [selectedFile2, setSelectedFile2] = useState<any>('');
	const [selectedFile3, setSelectedFile3] = useState<any>('');
	const [selectedFile4, setSelectedFile4] = useState<any>('');

	const [noOfImage, setNoOfImage] = useState<any>(0);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const addEventForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			eventName: '',
			dateOfEvent: '',
		},
		validate: (values) => {
			const errors: {
				eventName?: string;
				dateOfEvent?: string;
			} = {};
			if (!values.eventName) {
				errors.eventName = 'Required';
			}
			if (!values.dateOfEvent) {
				errors.dateOfEvent = 'Required';
			}
			return errors;
		},
		onSubmit: () => { onUpload() },
	});

	const handleFileChange1 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1)
			setSelectedFile1(e.target.files[0]);
		}
	};

	const handleFileChange2 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1)
			setSelectedFile2(e.target.files[0]);
		}
	};

	const handleFileChange3 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1)
			setSelectedFile3(e.target.files[0]);
		}
	};

	const handleFileChange4 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setNoOfImage(noOfImage + 1)
			setSelectedFile4(e.target.files[0]);
		}
	};

	const handleDeleteAvatar1 = () => {
		setNoOfImage(noOfImage - 1)
		setSelectedFile1(null)
		const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar2 = () => {
		setNoOfImage(noOfImage - 1)
		setSelectedFile2(null)
		const fileInput = document.getElementById('fileInput2') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar3 = () => {
		setNoOfImage(noOfImage - 1)
		setSelectedFile3(null)
		const fileInput = document.getElementById('fileInput3') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar4 = () => {
		setSelectedFile4(null);
		setNoOfImage(noOfImage - 1)
		const fileInput = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	function onUpload() {
		showLoader(true);

		if (selectedFile1 != '' || selectedFile2 != '' || selectedFile3 != '' || selectedFile4 != '') {
			const filedata = new FormData();
			filedata.append('licenseKey', getLicenseKey.toString());
			filedata.append('staffDetailsId', userAccountId);
			filedata.append('eventName', addEventForm.values.eventName);
			filedata.append('dateOfEvent', addEventForm.values.dateOfEvent);
			filedata.append('noOfImage', noOfImage);
			selectedFile1 != '' && filedata.append('imagePath1', selectedFile1, selectedFile1.name);
			selectedFile2 != '' && filedata.append('imagePath2', selectedFile2, selectedFile2.name);
			selectedFile3 != '' && filedata.append('imagePath3', selectedFile3, selectedFile3.name);
			selectedFile4 != '' && filedata.append('imagePath4', selectedFile4, selectedFile4.name);
			addEventDetails(
				filedata,
				(response) => {
					if (response.data.success == true) {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset()
					} else {
						showLoader(false);
						setAlertStatus({ message: response.data.message, type: 'warning' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: 'Image not upload', type: 'error' });
					setIsOpen(true);
					setIsNavigate(null);
				},
			);
		} else {
			showLoader(false);
			setAlertStatus({ message: 'Please select image', type: 'error' });
			setIsOpen(true);
			setIsNavigate(null);
		}
	}

	function closeAndReset() {
		addEventForm.resetForm()
		setSelectedFile1('')
		setSelectedFile2('')
		setSelectedFile3('')
		setSelectedFile4('')
		const fileInput1 = document.getElementById('fileInput1') as HTMLInputElement;
		const fileInput2 = document.getElementById('fileInput2') as HTMLInputElement;
		const fileInput3 = document.getElementById('fileInput3') as HTMLInputElement;
		const fileInput4 = document.getElementById('fileInput4') as HTMLInputElement;
		if (fileInput1) {
			fileInput1.value = '';
		}
		if (fileInput2) {
			fileInput2.value = '';
		}
		if (fileInput3) {
			fileInput3.value = '';
		}
		if (fileInput4) {
			fileInput4.value = '';
		}
	}

	return (
		<PageWrapper title='Event Details'>
			<Page container='fluid'>
				<Card>
					<CardHeader borderSize={1}>
						<CardLabel icon='List' iconColor='info' className='col-lg-5'>
							<CardTitle tag='div' className='h5'>
								Event Details
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive'>
						<div className='row g-4'>
							<div className='col-3'></div>
							<div className='col-3'>
								<FormGroup id='eventName' label='Event Name' isFloating>
									<Input
										placeholder='Enter Event Name'
										onChange={addEventForm.handleChange}
										value={addEventForm.values.eventName}
										isValid={addEventForm.isValid}
										onBlur={addEventForm.handleBlur}
										isTouched={addEventForm.touched.eventName}
										invalidFeedback={addEventForm.errors.eventName}
									/>
								</FormGroup>
							</div>
							<div className='col-3'>
								<FormGroup id='dateOfEvent' label='Event Date' isFloating>
									<Input
										placeholder='Event Date'
										type='date'
										isValid={addEventForm.isValid}
										onBlur={addEventForm.handleBlur}
										isTouched={addEventForm.touched.dateOfEvent}
										invalidFeedback={addEventForm.errors.dateOfEvent}
										onChange={addEventForm.handleChange}
										value={addEventForm.values.dateOfEvent}
									/>
								</FormGroup>
							</div>
						</div>
						<br />
						<div className='row'>
							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile1 ? (
											<img
												src={URL.createObjectURL(selectedFile1)}
												alt=''
												width={138}
												height={138}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}

									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput1'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange1}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar1}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile2 ? (
											<img
												src={URL.createObjectURL(selectedFile2)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput2'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange2}
												disabled={selectedFile1 != '' ? false : true}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar2}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile3 ? (
											<img
												src={URL.createObjectURL(selectedFile3)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput3'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange3}
												disabled={selectedFile2 != '' ? false : true}
											/>

											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar3}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>

							<div className='col-3'>
								<Card stretch shadow={'md'} className='border border-light'>
									<CardBody>
										{selectedFile4 ? (
											<img
												src={URL.createObjectURL(selectedFile4)}
												alt=''
												width={128}
												height={128}
												className='mx-auto d-block img-fluid mb-3 rounded'
											/>
										) : (
											<img
												src={profilePic(0)}
												alt=''
												width={140}
												height={140}
												className='mx-auto d-block img-fluid rounded'
											/>
										)}
									</CardBody>
									<CardFooter borderSize={1}>
										<div className='col-12'>
											<Input
												className='ms-1'
												id='fileInput4'
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange4}
												disabled={selectedFile3 != '' ? false : true}
											/>
											<Button
												style={{ marginLeft: '65px' }}
												className='mt-3'
												color='dark'
												isLight
												icon='Delete'
												onClick={handleDeleteAvatar4}>
												Delete
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
							<br />
						</div>
						<br />
						<div className='col-12 d-flex justify-content-end'>
							<Button icon='Save' color='info' type='submit' onClick={addEventForm.handleSubmit}>
								Submit
							</Button>
						</div>
					</CardBody>
				</Card>
			</Page>
			<AlertService
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				alertStatus={alertStatus}
				isNavigate={isNavigate}
			/>
		</PageWrapper>
	);
};

export default EventDetails;
