import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import AuthContext from '../../../../contexts/authContext';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { getCurrentDateAndTime, convertDateToEpoch } from '../../../../services/common.service';
import { getScheduleDetailsByDate } from '../../../../services/timeTableService';
import { toasts } from '../../../../services/toast.service';
import Card, { CardTitle, CardBody, CardHeader, CardActions, CardLabel } from '../../../../components/bootstrap/Card';
import { useFormik } from 'formik';
import { getStudentTotalFeeCollection } from '../../../../services/fee.service';
import Icon from '../../../../components/icon/Icon';
import HumansWebp from '../../../../assets/img/scene2.webp';
import Humans from '../../../../assets/img/scene2.webp';

const StudentDashboardSchedule = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getScheduleDetailsList();
		getStudentTotalFeeCollectionList(userAccountId);
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [scheduleData, setScheduleData] = useState<any>([]);
	const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([]);
	const [balanceAmt, setBalanceAmt] = useState<number>(0);


	const staffScheduleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			date: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				date?: string;
			} = {};

			if (!values.date) {
				errors.date = 'Required';
			}
			return errors;
		},

		//validateOnChange: false,
		onSubmit: () => { },
	});

	const [dayOrder, setDayOrder] = useState<any>('');
	const [dayId, setDayId] = useState<any>('');
	const [dataStatus, setDataStatus] = useState<any>(false);

	function getScheduleDetailsList() {
		let scheduleDate = staffScheduleForm.values.date;
		let dateInMilliSec = convertDateToEpoch(scheduleDate);

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setDataStatus(true)
						setScheduleData(data);
						setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true)
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setScheduleData([]);
					setDataStatus(true)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataStatus(true)
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataStatus(true)
			},
		);
	}

	function getStudentTotalFeeCollectionList(studentDetailsId: number) {
		getStudentTotalFeeCollection(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentTotalFeeCollection;
					if (data != undefined) {
						setStudentTotalFeeCollectionData(data);
						setBalanceAmt(
							data[0].semFees_netPayable +
							data[0].prevSemFees_netPayable +
							data[0].busFees_netPayable +
							data[0].prevBusFees_netPayable +
							data[0].extraFees_netPayable,
						);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	return (
		<PageWrapper title='Schedule'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-9'>
						<Card stretch className='border border-light' shadow={'md'}>
							<CardHeader>
								<CardLabel icon='Schedule' iconColor='info' className="col-lg-5">
									<CardTitle tag='div' className='h5'>Schedule</CardTitle>
								</CardLabel>&nbsp;&nbsp;
								<CardActions className="d-print-none">
									<span className='text-success fs-5'><b>{dayOrder}</b></span>
								</CardActions>
							</CardHeader>
							<CardBody>

								{dataStatus ? <>

									{scheduleData != '' && dayId > 0 && dayId != 7 ?
										<div className='row'>
											{scheduleData.map((period: any) => (
												<div className='col-4' key={period.periodDetailsId}>
													<Card stretch shadow={'md'} className="border border-light">
														<CardBody>
															<div className='row align-items-center'>
																<div className='col d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<div
																			className='ratio ratio-1x1'
																			style={{ width: 65 }}>
																			<div className={classNames(
																				'rounded-2', 'd-flex align-items-center justify-content-center',
																				{ 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus }
																			)}>
																				<span className='fw-bold'>
																					<span className='text-info' style={{ fontSize: '23px'}}>
																						{period.periodCount}
																					</span>
																					{/* <br />
																					<small className='text-dark' style={{ fontSize: '10px' }}>
																						period
																					</small> */}
																				</span>
																			</div>
																		</div>
																	</div>
																	<div className='flex-grow-1 ms-2 d-flex justify-content-between align-items-center'>
																		<div>
																			<small className='fw-bold mb-0'>
																				{period.paperName}
																			</small>
																			<div className=' text-info fs-6 mb-0'>
																				{period.staffName}
																			</div>
																			<div className='text-info'>
																				<small>
																					<span className='text-black-50 fw-bold'>
																						{
																							period.startTime
																						}
																						-
																						{period.endTime}
																					</span>
																				</small>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>
											))}
										</div> :
										scheduleData != '' && (dayId == 0 || dayId == 7) ?
											<div className="col-12" style={{ height: '61vh' }}>
												<div className='row'>
													<div className="col-12" style={{ height: '15vh' }}></div>
													<div className='col-12 d-flex justify-content-center'>
														<Icon icon='CalendarToday' size={'10x'} />
													</div>
													<div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
														Holiday
													</div>
												</div>
											</div> :
											<div className='row'>
												<div className='col-12 d-flex justify-content-center'>
													<img
														srcSet={HumansWebp}
														src={Humans}
														alt='Humans'
														style={{ height: '50vh' }}
													/>
												</div>
												<div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
													Schedule Not Allocated
												</div>
												<br /><br /><br /><br />
											</div>
									}
								</> : null
								}
							</CardBody>
						</Card>
					</div>

					<div className='col-3'>
						<Card stretch className='border border-light' shadow={'md'}>
							<CardHeader>
								<CardLabel icon='MonetizationOn' iconColor='info' className="col-lg-12">
									<CardTitle tag='div' className='h5'>Fee Details</CardTitle>
								</CardLabel>&nbsp;&nbsp;
							</CardHeader>
							<CardBody>
								{studentTotalFeeCollectionData.map((studentTotalFee: any) => (
									<>
										<div className='col-md-12' key={studentTotalFee.studentDetailsId}>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-primary bg-l${darkModeStatus ? 'o50' : '10'
													}-primary-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-3 mt-3' style={{height: '22px'}}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-primary',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	T
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-primary mb-0'>
																₹
																{studentTotalFee.termFees_grossPayable +
																	studentTotalFee.busFees_grossPayable +
																	studentTotalFee.extraFees_netPayable}
															</div>
															<div className='text-muted'>
																<b> TOTAL</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-success bg-l${darkModeStatus ? 'o50' : '10'
													}-success-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-3 mt-3' style={{height: '22px'}}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-success',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	P
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-success mb-0'>
																₹{' '}
																{studentTotalFee.termFees_amountPaid +
																studentTotalFee.termFees_concession +
																studentTotalFee.busFees_concession +
																studentTotalFee.extraFees_concession +
																	studentTotalFee.busFees_amountPaid +
																	studentTotalFee.extraFees_amountPaid}
															</div>
															<div className='text-muted'>
																<b>PAID</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-danger bg-l${darkModeStatus ? 'o50' : '10'
													}-danger-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-3 mt-3' style={{height: '22px'}}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-danger',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	B
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-danger mb-0'>
																₹{' '}
																{studentTotalFee.termFees_netPayable +
																	studentTotalFee.busFees_netPayable +
																	studentTotalFee.extraFees_netPayable}
															</div>
															<div className='text-muted'>
																<b> BALANCE</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card 
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-warning bg-l${darkModeStatus ? 'o50' : '10'
													}-warning-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div className='d-flex align-items-center pb-3 mt-3' style={{height: '22px'}}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-warning',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	F
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-warning mb-0'>
																₹ {studentTotalFee.totalFineAmount}
															</div>
															<div className='text-muted'>
																<b>FINE</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</>
								))}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default StudentDashboardSchedule;
